@import url('https://fonts.googleapis.com/css2?family=Tulpen+One&display=swap');

.NavBar {
    background-color: black;
}

.title {
    z-index: 100;
    height: 8em;
    margin-left: 0;
    padding: 0;
    -webkit-filter: drop-shadow(0 0 0.75rem rgba(255, 255, 255, 0.5));
    filter: drop-shadow(0 0 0.75rem rgba(255, 255, 255, 0.5));
}

.keyboard {
    height: 8em;
    margin-right: 0;
    margin-left: auto;
    padding: 0;
}

.socials {
    position: absolute;
    bottom: 0px;
}

main {
    width: 100vw;
}

.coffee-container {
    width: 100%;
    text-align: center !important;
}

.coffee {
    width: 75%;
}

@media only screen and (max-width: 600px) {
    .title {
        z-index: 100;
        width: 75%;
        height: auto;
        margin-left: 0;
        margin-top: 0;
        padding: 0;
    }
}

@media only screen and (min-width: 600px){
    #mobileDrawer {
        width: 0px;
    }
}

@media only screen and (min-width: 1557px) and (max-width: 1620px) {
    main {
        margin-left: 40px;
    }
    
}

@media only screen and (min-width: 1500px) and (max-width: 1556px) {
    main {
        margin-left: 50px;
    }
}

@media only screen and (min-width: 1422px) and (max-width: 1499px) {
    main {
        margin-left: 100px;
    }
}

@media only screen and (min-width: 1396px) and (max-width: 1421px) {
    main {
        margin-left: 110px;
    }
}

@media only screen and (min-width: 1388px) and (max-width: 1395px) {
    main {
        margin-left: 120px;
    }
}

@media only screen and (min-width: 1306px) and (max-width: 1387px) {
    main {
        margin-left: 160px;
    }
}

@media only screen and (min-width: 1266px) and (max-width: 1305px) {
    main {
        margin-left: 180px;
    }
}

@media only screen and (min-width: 1220px) and (max-width: 1265px) {
    main {
        margin-left: 200px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1219px) {
    main {
        margin-left: 210px;
    }
}

@media only screen and (min-width: 1150px) and (max-width: 1199px) {
    main {
        margin-left: 145px;
    }
}


@media only screen and (min-width: 1100px) and (max-width: 1149px) {
    main {
        margin-left: 165px;
    }
}

@media only screen and (min-width: 1049px) and (max-width: 1099px) {
    main {
        margin-left: 195px;
    }
}

@media only screen and (min-width: 1030px) and (max-width: 1048px) {
    main {
        margin-left: 200px;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1029px) {
    main {
        margin-left: 220px;
    }
}

@media only screen and (min-width: 994px) and (max-width: 1024px) {
    main {
        margin-right: 50px;
        margin-left: 145px;
    }
}


@media only screen and (min-width: 992px) and (max-width: 993px) {
    main {
        margin-right: 50px;
        margin-left: 150px;
    }
}

@media only screen and (min-width: 980px) and (max-width: 991px) {
    main {
        margin-left: 30px;
    }
}

@media only screen and (min-width: 950px) and (max-width: 979px) {
    main {
        margin-left: 45px;
    }
}

@media only screen and (min-width: 925px) and (max-width: 949px) {
    main {
        margin-left: 60px;
    }
}

@media only screen and (min-width: 900px) and (max-width: 924px) {
    main {
        margin-left: 80px;
    }
}


@media only screen and (min-width: 845px) and (max-width: 899px) {
    main {
        margin-left: 105px;
        margin-right: 20px;
    }
}

@media only screen and (min-width: 801px) and (max-width: 844px) {
    main {
        margin-left: 120px;
        margin-right: 20px;
    }
}


@media only screen and (min-width: 767px) and (max-width: 800px) {
    main {
        margin-left: 130px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 766px) {
    main {
        margin-left: 220px;
    }
}

.small-socials {
    bottom: 15px;
}

.drawer-header {
    display: flex;
    justify-content: center;
    padding: 1em;
}

.title-bg {
    height: 140px;
}

.header-title {
    font-family: 'Tulpen One', cursive;
    position: absolute;
    font-size: 45.4pt;
}

.header-sub-title {
    position: absolute;
    top: 90px;
    left: 10px;
    font-style: italic;
    display: flex;
    flex-direction: column;
    color: #b0c0e9;
    font-size: 15.7pt;
}

.mobile-site-header {
    display: flex;
    justify-content: center;
    padding: .30em;
    width: 100%;
    height: 7em;
}

.mobile-title-bg {
    height: 80px;
}

.mobile-header-title {
    font-family: 'Tulpen One', cursive;
    position: absolute;
    top: .01 em;
    font-size: 3em;
}

.mobile-header-sub-title {
    position: absolute;
    font-style: italic;
    color: #b0c0e9;
    font-size: 1em;
    top: 4em;
    text-align: center;
}

@media only screen and (max-width: 599px) {
    .header-title {
        display: none;
    }

    .header-sub-title {
        display: none;
    }
}
