/* FONTS */

@font-face {
  font-family: 'Linux Biolinum';
  src: url('./fonts/LinBiolinum_R.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Linux Biolinum';
  src: url('./fonts/LinBiolinum_RI.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Linux Biolinum';
  src: url('./fonts/LinBiolinum_RB.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}


html {
  width: 100vw;
}

body {
  margin: 0;
  font-family: Linux Biolinum, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: black !important;
  background: url('./assets/images/bg-curtains.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  
  font-family: Linux Biolinum !important;
  font-size: 1.1rem !important;
  overflow-x: hidden;
}

code {
  font-family: Linux Biolinum, sans-serif;
}

@media only screen and (max-width: 600px) {
  body {
    background: url('./assets/images/bg-curtains-mobile.jpg') no-repeat center center fixed;
    background-size: cover;
  }
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.25rem;
  color: #eba6a6;
}

h3 {
  font-size: 1rem;
  color: #ffffff;
  font-style: italic;
}

p {
  font-size: 1.1rem;
}

a:link {
  color:#ffffff;
  text-decoration:none;
}

a:visited {
  color:#ffffff;
  text-decoration:none;
}

a:hover {
  color:#ffffff;
  text-decoration:none;
}

a:active {
  color:#ffffff;
  text-decoration:none;
}

/* Styling for text, links, and boarder box on all pages */

.date {
  font-weight: normal;
}

.download {
  font-size: 1rem;
  color:#b0c0e9;
}

.WhatsNew, .Talents, .RecordingsOriginal, .RecordingsOther, .SheetMusicOther {
  margin-top: 2em;
  color: white;
  text-align: center;
}

@media only screen and (max-width: 599px) {
  .WhatsNew, .Talents, .RecordingsOriginal, .RecordingsOther, .SheetMusicOther {
    margin-top: 125px;
  }
}

.header-container {
  padding-bottom: 25px;
}

.newText a:link {
  color:#508ED8;
  text-decoration:none;
}

.newText a:visited {
  color:#8E79A5;
  text-decoration:none;
}

.newText a:hover {
  color:#7EBAFF;
  text-decoration:none;
}

.newText a:active {
  color:#ffffff;
  text-decoration:none;
}



@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .WhatsNew, .Talents, .RecordingsOriginal, .Home, .SheetMusicOther {
    width: 95%;
    margin-left: 76px;
  }
}

/* fade in */
.fade-in {
  animation: fade 0.9s ease-in;
}

@keyframes fade {
  0% {
      opacity: 0;
  }
}

.newText iframe
{ 
    max-width: 100%;
}
