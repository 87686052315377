.Home {
    margin-top: 2em;
    color: white;
    text-align: center;
}

.aboutMe {
    justify-content: space-between;
}

.photo {
    margin: auto;
    margin-right: 10px;
    border: darkgray solid 2px !important;
}

.left-align {
    float: left;
    margin: 5px;
}

.right-align {
    float: right;
    margin: 5px;
}

@media only screen and (max-width: 800px) {
    .imgContainer {
        width: 100%;
    }

    .photo {
        margin-right: 0;
    }
}

@media only screen and (max-width: 599px) {
    .Home {
        margin-top: 125px;
    }
}