.soundCloud {
    font-size: 10px;
    color: #cccccc;
    line-break: anywhere;
    word-break: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;
    font-weight: 100;
}

.soundCloud-userName {
    color: #cccccc;
    text-decoration: none;
    margin-left: 1.5em
}

.youtubeContainer {
    position: relative;
    padding-bottom: 50%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.youtubeContainer iframe, .youtubeContainer object, .youtubeContainer embed
{ 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.newText hr:last-child {
    display: none;
}

.MuiAccordion-root:last-of-type, #panel5d-content>.MuiAccordionDetails-root {
    border-radius: 0 0 10px 10px;
}

#panel5d-header[aria-expanded="false"] {
    border-radius: 0 0 10px 10px;
}



.MuiAccordion-root:first-of-type, #panel1d-header[aria-expanded="false"], #panel1d-header, #primaryAccordion {
    border-radius: 10px 10px 0 0;
}