.submit-btn {
    background-color: #eba6a6;
    padding: .5em;
    border-radius: 2em;
    border: 1px #5d4545 solid;
    font-size: 1.25em;
    -webkit-box-shadow: 3px 3px 15px #000;
    -moz-box-shadow: 3px 3px 15px #000;
    box-shadow: 3px 3px 15px #000;
}

.submit-btn:hover {
    background-image: radial-gradient(#fff , #eba6a6);
    transition: all 0.3s ease 0s;
}
